@use '@angular/material' as mat;

@use 'bootstrap/scss/bootstrap-grid';
@use 'bootstrap/scss/bootstrap-utilities';

@import 'reset-css/reset.css';
@import 'normalize.css';

html {
  @include mat.theme(
    (
      color: (
        theme-type: dark,
      ),
      typography: Sofia Sans Semi Condensed,
      density: 0,
    )
  );
}

body {
  color: var(--mat-sys-on-surface);

  font-family:
    Sofia Sans Semi Condensed,
    sans-serif;
  font-weight: 700;

  background: var(--mat-sys-surface-container-lowest);
}

.mat-drawer {
  height: 100vh !important;
  width: 100vw !important;

  position: fixed !important;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  &-content {
    max-height: calc(100vh - var(--mat-toolbar-standard-height, 64px) - 1rem);

    &:not(:empty) {
      margin-top: 1rem;

      padding: 0 1rem var(--mat-toolbar-standard-height, 64px) 1rem;
    }

    flex: 1;

    overflow-y: auto;
  }

  &-container {
    z-index: 10 !important;
  }

  &-inner-container {
    overflow: hidden !important;
  }
}

.mat-list-item {
  padding-right: 0 !important;
  padding-left: 0 !important;

  img[matListItemAvatar] {
    margin-left: 0 !important;
  }
}

.mat-hide-hint {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.mat-icon {
  font-family: 'Material Symbols Outlined' !important;
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48 !important;
}
